<template>
  <v-container>
    <v-layout row wrap justify-space-between class="mb-2">
      <v-flex xs6 sm4 md4 lg4 class="mb-1">
        <v-btn
          :color="$store.state.secondaryColor"
          outlined
          small
          @click="viewPurchaseOrders"
        >
          <v-icon small left>mdi-arrow-left</v-icon>
          <span class="caption">{{ $t("back") }}</span>
        </v-btn> </v-flex
      ><v-spacer></v-spacer>
      <v-flex xs12 sm6 md4 lg4 class="text-right">
        <v-btn dark small class="gradients" @click="printPage()">
          <v-icon small left>mdi-download</v-icon>
          {{ $t("download") }}
        </v-btn>
      </v-flex>
    </v-layout>
    <div v-if="pleaseWait" class="mt-3">
      <PleaseWait></PleaseWait>
    </div>
    <div v-else>
      <v-card>
        <!-- Purchase order document layout -->
        <div class="body" id="print">
          <div class="container">
            <div class="row right">
              <div class="column-quarter">&nbsp;</div>
              <div class="column-quarter">&nbsp;</div>
              <div class="column-quarter">&nbsp;</div>
              <div class="column-quarter">
                <span class="bold-text title">{{ $t("purchase order") }}</span>
              </div>
            </div>
            <!-- ######Picture date po number row####### -->

            <div class="row">
              <div class="column-half left">
                <span class="bold-text"
                  >{{ $t("business name") }}:
                  <span class="light-text">{{
                    company.businessName
                  }}</span></span
                ><br />
                <span class="bold-text"
                  >{{ $t("address") }} :
                  <span class="light-text"
                    >{{ company.address.building }} ,{{
                      company.address.city
                    }}
                    ,{{ company.address.country }}</span
                  ></span
                ><br />
                <span class="bold-text"
                  >{{ $t("telephone") }}:
                  <span class="light-text">{{ company.phone }}</span></span
                ><br />
                <span class="bold-text"
                  >{{ $t("email") }}:
                  <span class="light-text">{{ company.email }}</span></span
                ><br />
                <span class="bold-text"
                  >{{ $t("website") }}:
                  <span class="light-text">{{ company.website }}</span></span
                >
              </div>

              <div class="column-half right" style="font-size: 13px">
                <span class="bold-text"
                  >{{ $t("date") }}:
                  <span class="light-text">{{
                    formatDate(purchaseOrder.dateTime)
                  }}</span></span
                >
                <br />
                <span class="bold-text"
                  >{{ $t("purchase order number") }} #:
                  <span class="light-text">{{
                    purchaseOrder.orderNo
                  }}</span></span
                >
              </div>
            </div>
            <!-- ####supplier and customer details#########  -->
            <div class="row">
              <div class="column-half left">
                <button class="button gradients">{{ $t("vendor") }}</button>
              </div>

              <div class="column-half left">
                <button class="button gradients">
                  {{ $t("ship to") }}
                </button>
              </div>
            </div>
            <div class="row">
              <div class="column-half left">
                <span class="bold-text"
                  >{{ $t("supplier name") }}:
                  <span class="light-text">{{ supplier.name }}</span></span
                ><br />
                <span class="bold-text"
                  >{{ $t("address") }}:
                  <span class="light-text">{{ supplierAddress }}</span></span
                ><br />
                <span class="bold-text"
                  >{{ $t("telephone") }}:
                  <span class="light-text">{{ supplier.phone }}</span></span
                ><br />
                <span class="bold-text"
                  >{{ $t("email") }}:
                  <span class="light-text">{{ supplier.email }}</span></span
                >
              </div>

              <div class="column-half left">
                <span class="bold-text"
                  >{{ $t("business name") }}:
                  <span class="light-text">{{
                    company.businessName
                  }}</span></span
                ><br />
                <span class="bold-text"
                  >{{ $t("address") }} :
                  <span class="light-text"
                    >{{ company.address.building }} ,{{
                      company.address.city
                    }}
                    ,{{ company.address.country }}</span
                  ></span
                ><br />
                <span class="bold-text"
                  >{{ $t("telephone") }}:
                  <span class="light-text">{{ company.phone }}</span></span
                ><br />
                <span class="bold-text"
                  >{{ $t("email") }}:
                  <span class="light-text">{{ company.email }}</span></span
                >
              </div>
            </div>
            <!-- ########Shipping terms###### -->
            <div class="row">
              <div class="column right">
                <button class="button gradients">
                  <div class="row center">
                    <div class="column-third">
                      <span class="bold-text">{{ $t("shipping terms") }}</span>
                    </div>
                    <div class="column-third">
                      <span class="bold-text">{{ $t("shipping method") }}</span>
                    </div>
                    <div class="column-third">
                      <span class="bold-text">{{ $t("delivery date") }}</span>
                    </div>
                  </div>
                </button>
              </div>
            </div>

            <div class="row">
              <div class="column-third center">
                <span class="bolder-text">{{
                  purchaseOrder.shippingTerms
                }}</span>
              </div>
              <div class="column-third center">
                <span class="bolder-text">{{
                  purchaseOrder.shippingMethod
                }}</span>
              </div>
              <div class="column-third right">
                <span class="bolder-text"
                  >{{ formatDate(purchaseOrder.deliveryDate) }}
                </span>
              </div>
            </div>

            <!-- ########Product list###### -->
            <div class="row">
              <button class="button gradients">
                <div class="row center">
                  <div class="column-extra-large">
                    <div class="column-twenty-percent">
                      <span class="bold-text">{{ $t("item") }}#</span>
                    </div>
                    <div class="column-forty-percent">
                      <span class="bold-text">{{ $t("description") }}</span>
                    </div>
                    <div class="column-twenty-percent">
                      <span class="bold-text">{{ $t("quantity") }}</span>
                    </div>
                    <div class="column-twenty-percent">
                      <span class="bold-text">{{ $t("unit price") }}</span>
                    </div>
                  </div>
                  <div class="column-small" style="padding-top: 20px">
                    <span class="bold-text">{{ $t("total") }}</span>
                  </div>
                </div>
              </button>
            </div>

            <div v-for="(item, i) in purchaseOrder.products" :key="i">
              <div class="row center">
                <div class="column-extra-large">
                  <div class="column-twenty-percent">
                    <span class="bold-text">{{ item.name }} </span>
                    <br />
                    <span class="light-text" style="font-size: 12px"
                      >{{ item.barcodeNo }}
                    </span>
                  </div>
                  <div class="column-forty-percent">
                    <span class="">{{ item.description }} </span>
                  </div>
                  <div class="column-twenty-percent">
                    <span class="bold-text">{{ item.quantity }} </span>
                  </div>
                  <div class="column-twenty-percent">
                    <span class="bold-text"
                      >{{ currencyFormat(item.unitPrice) }}
                    </span>
                  </div>
                </div>
                <div class="column-small" style="padding-top: 20px">
                  <span class="bold-text">{{
                    currencyFormat(item.total)
                  }}</span>
                </div>
              </div>
              <hr class="grey-hr" />
            </div>

            <!-- ########Shipping terms###### -->
            <div class="row">
              <div class="column right">
                <div class="row center">
                  <div class="column-half">
                    <span class="bold-text left" style="font-size: 12px"
                      >Comments/ Special instructions
                    </span>
                    <textarea
                      class="left"
                      name=""
                      id=""
                      v-model="purchaseOrder.comments"
                      rows="10"
                      style="width: 100%; border: 1px solid; border-radius: 3px"
                      readonly
                    ></textarea>
                  </div>
                  <div class="column-half">
                    <div class="row left" style="margin-top: 15px">
                      <div class="column-forty-percent left">
                        <span class="bold-text">Subtotal </span>
                      </div>
                      <div class="column-sixty-percent right">
                        <span class=""
                          >{{ currencyFormat(purchaseOrder.subtotal) }}
                        </span>
                      </div>
                    </div>
                    <hr class="grey-hr" />
                    <div class="row center" style="margin-top: 15px">
                      <div class="column-forty-percent left">
                        <span class="bold-text">Sales Tax </span>
                      </div>

                      <div class="column-sixty-percent right">
                        <span class=""
                          >{{ currencyFormat(purchaseOrder.salesTax) }}
                        </span>
                      </div>
                    </div>
                    <hr class="grey-hr" />
                    <div class="row" style="margin-top: 15px">
                      <div class="column-forty-percent left left">
                        <span class="bold-text" style="margin-left: 0px"
                          >Shipping & Handling
                        </span>
                      </div>

                      <div class="column-sixty-percent right">
                        <span class=""
                          >{{ currencyFormat(purchaseOrder.shippingCosts) }}
                        </span>
                      </div>
                    </div>
                    <hr class="grey-hr" />
                    <div class="row center" style="margin-top: 15px">
                      <div class="column-forty-percent left">
                        <span class="bold-text">Other Costs </span>
                      </div>
                      <div class="column-sixty-percent right">
                        <span class=""
                          >{{ currencyFormat(purchaseOrder.otherCosts) }}
                        </span>
                      </div>
                    </div>
                    <hr class="grey-hr" />
                    <div class="row center" style="margin-top: 15px">
                      <div class="column-forty-percent left">
                        <span class="bold-text">Discount </span>
                      </div>

                      <div class="column-sixty-percent right">
                        <span class=""
                          >{{ currencyFormat(purchaseOrder.discount) }}
                        </span>
                      </div>
                    </div>
                    <hr class="grey-hr" />
                    <div class="row center" style="margin-top: 15px">
                      <div class="column-forty-percent left">
                        <span class="bold-text">Grand Total </span>
                      </div>

                      <div class="column-sixty-percent right">
                        <span class="bold-text"
                          >{{ currencyFormat(purchaseOrder.total) }}
                        </span>
                      </div>
                    </div>
                    <hr class="grey-hr" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-card>
    </div>
  </v-container>
</template>

<script>
import db from "@/plugins/fb";
import PleaseWait from "@/components/templates/PleaseWait";

import { printer } from "@/plugins/functions";
export default {
  components: {
    PleaseWait,
  },
  data: () => ({
    purchaseOrder: "",
    suppliers: [],
    pleaseWait: true,
    // company:"",
  }),

  created() {
    this.getOrderDetails();
    this.getCompanyInfo();
    this.getSuppliers();
  },

  computed: {
    company() {
      return this.getCompanyInfo(this.purchaseOrder.businessId);
    },
    address() {
      return `${this.company.address.building}, ${this.company.address.street},
           ${this.company.address.city}, ${this.company.address.country}`;
    },
    supplierAddress() {
      return `${this.supplier.address.building}, ${this.supplier.address.street},
            ${this.supplier.address.city}, ${this.supplier.address.country}`;
    },
    businessOwner() {
      return this.$store.state.currentUser;
    },

    supplier() {
      return this.getSupplierInfo(this.purchaseOrder.supplierId);
    },
  },

  methods: {
    printer,
    getOrderDetails() {
      this.pleaseWait = true;
      db.collection("purchaseOrders")
        .doc(this.$route.params.id)
        .get()
        .then((doc) => {
          this.purchaseOrder = doc.data();

          this.pleaseWait = false;
        })
        .catch((error) => {
          console.log("Error getting  document:", error);
        });
    },
    getSuppliers() {
      db.collection("suppliers")
        .where("ownerId", "==", this.businessOwner)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.suppliers.push({
              id: doc.id,
              ...doc.data(),
            });
          });
          this.loading = false;
        });
    },

    getSupplierInfo(id) {
      let all = this.suppliers;
      let suplierInfo = all.find((item) => item.id == id);
      return suplierInfo;
    },

    getCompanyInfo(id) {
      let all = this.$store.getters.getBusinesses;
      // let companyId = this.purchaseOrder.businessId
      let companyInfo = all.find((item) => item.id == id);
      return companyInfo;
      // console.log('Company: ', companyInfo)
      // this.company = current
    },

    formatDate(val) {
      var date = val.toDate();
      return date;
    },

    printPage() {
      this.snackInfo = true;
      this.printLoading = false;
      this.printer("print", "PurchaseOrder");
    },
    currencyFormat(val) {
      return this.$store.state.currency.symbol + " " + Number(val).toFixed(2);
    },
    //redirect back to po list
    viewPurchaseOrders() {
      this.$router.push({ name: "ViewPurchaseOrders" });
    },
  },
};
</script>

<style scoped>
.white-hr {
  border: 1px dashed white;
  background-color: white;
}
.black-hr {
  border-top: 1px solid #000000;
  height: 1px;
  border-radius: 5px;
}
.grey-hr {
  border-top: 1px solid #cecece;
  height: 1px;
  border-radius: 5px;
}
.black-background {
  background-color: #000000;
}
.gradients {
  background-image: linear-gradient(to bottom right, #0579a3, #005270);

  /* background-image: linear-gradient(to bottom right, #072033,#103c5e); */
}
.white-text {
  color: white;
}

.center {
  text-align-last: center;
}

.center {
  text-align-last: center;
}
.left {
  text-align-last: left;
}

.right {
  text-align-last: right;
}
/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}
.title {
  color: #ed6d1e;
}

.body {
  background-color: #e0e0e0;
}
.body .container {
  background: black (0, 0, 0); /* Fallback color */
  color: grey (126, 126, 126);
  font-size: 15px;
  background-color: white;
}
.bold-text {
  font-weight: bolder;
}
.light-text {
  font-weight: lighter;
}
.column {
  float: left;
  width: 100%;
  padding: 10px;
}

.column-half {
  float: left;
  width: 50%;
  padding: 10px;
}

.column-third {
  float: left;
  width: 33.33%;
  padding: 10px;
}

.column-two-third {
  float: left;
  width: 66.66%;
  padding: 10px;
}

.column-quarter {
  float: left;
  width: 25%;
  padding: 10px;
}

.column-three-quarter {
  float: left;
  width: 75%;
  padding: 10px;
}
.column-small {
  float: left;
  width: 15%;
  padding: 10px;
}
.column-extra-large {
  float: left;
  width: 85%;
  padding: 10px;
}
.column-forty-percent {
  float: left;
  width: 40%;
  padding: 10px;
}
.column-sixty-percent {
  float: left;
  width: 60%;
  padding: 10px;
}
.column-twenty-percent {
  float: left;
  width: 20%;
  padding: 10px;
}
.button {
  background-color: #4caf50; /* Green */
  border: none;
  color: white;
  padding: 5px 15px;
  text-align: left;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;

  width: 100%;
}
</style>
